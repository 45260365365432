import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';
import PhoneNumber from '../components/phoneNumber';

const Contact = ({ location }) => (
    <Layout location={location}>
        <Seo
            title="Contact Us | MediCareMaster"
            description="If You Are Over 64 Then You Qualify For Medicare. We Help You Find The Right Coverage For You In Your Area From Trusted Providers."
            image="http://medicaremaster.org/src/images/slide03.jpg"
        />
        <div className="contact-us">
            <h1>Contact Us</h1>
            <p><span>MediCareMaster.org</span>is a complete free consumer service, with the goal to simplify the process of finding insurance options for our customers.</p>
            <p>We value everyone's feedback. Please feel free to contact us with thoughts, comments, suggestions, or questions regarding <Link to='/'>medicaremaster.org</Link>.<br />If you have suggestions on how we can improve our site or your experience with our website, please send us a note.</p>
            <p class="big">Email: <a href="mailto:info@blipmarketing.co">info@blipmarketing.co</a>
                <br />Phone: <PhoneNumber noIcon/></p>
        </div>
    </Layout>
);

export default Contact;
